$('#contact-form').on('submit', function(e) {
    e.preventDefault();

    var form = $(this);
    var isError = false;
    var error = form.find('.error').first();
    var info = form.find('.info').first();

    $.each(form.serializeArray(), function(i, item) {
        if (item.name == 'name' && item.value == '') {
            isError = true;
            error.html('Por favor, rellena un nombre de contacto.').slideDown();

            return false;
        } else if (item.name == 'email' && item.value == '') {
            isError = true;
            error.html('Por favor, rellena un email para ponernos en contacto contigo.').slideDown();

            return false;
        } else if (item.name == 'email' && !validateEmail(item.value)) {
            isError = true;
            error.html('Por favor, escribe una dirección de email válida.').slideDown();

            return false;
        } else if (item.name == 'message' && item.value == '') {
            isError = true;
            error.html('Por favor, escribe un mensaje para que podamos estudiar tu caso.').slideDown();

            return false;
        }
    });

    if (!isError) {
        error.html('&nbsp;').slideUp();
    } else {
        return false;
    }

    $.ajax({
        type: 'post',
        url: path + '/api/contact/',
        data: form.serialize(),
        dataType: 'json',
        success: function(result) {
            if (result.status == 'ok') {
                form.trigger('reset');
                info.html('Enviado.');
                setTimeout(function () {
                    info.html('&nbsp;').hide();
                }, 3000);
            } else if (result.status == 'error') {
                info.html('&nbsp;').hide();
                $.each(result.errors, function(i, item) {
                    error.html(item).slideDown();

                    return;
                });
            }
        },
        beforeSend: function()
        {
            info.html('Enviando...').show();
        },
        error: function()
        {
            info.html('&nbsp;').hide();
            error.html('No se ha podido enviar debido a un error en el servicio. Por favor, inténtalo de nuevo.').slideDown();
        }
    });
});

function validateEmail(email)
{
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
}

function validatePhone(phone)
{
    var phone = phone.replace(/ |-|\./g, '');
    var regex = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;

    return regex.test(phone);
}